.navbar {
    background-color: #4682B4;
}

.nav-item a{
    color: #fff;
    transition: .3s ease-in;
}

.nav-item a:hover{
    color: #F21667;
}