.form-login{
    max-width: 650px;
    }
    
    .btn-cadastro{
        background-color: #4682B4;
        color: #fff;
    }
    
    .btn-cadastro:hover {
        background-color: #F23667;
        color: #fff;
    }