.row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .motivo-cell {
    white-space: pre-line;
}

/* Estilo para os cards de fotos */
.uk-card-body-estilo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Defina a altura desejada para os cards */
}

/* Estilo para as imagens dentro dos cards */
.uk-card-body-estilo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ou 'cover', dependendo do efeito desejado */
}

.centralizado{
  display: flex;
  align-items: center;
  justify-content: center;
}

.uk-card-body-docs {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  overflow: hidden;
  -webkit-box-orient: vertical; 
  max-height: 200px;
}