.login-content {
    width: 100%;
    padding: 7%;
    margin: auto;
    background-color: #4682B4;
    height: 100vh;
}

.btn-login {
    background-color: #fff;
    color: #4682B4;
}

.btn-login:hover {
    background-color: #f21667;
    color: #fff;
}

.opcoes-login a {
    text-decoration: none;
    color: #fff;
    transition: .3s ease-in;
}

.opcoes-login a:hover {
    color: #f21667;
}

.icones i {
    text-decoration: none;
    color: #fff;
    transition: .3s ease-in;
}

.icones i:hover {
    color: #f21667;
}

.itens-menu:hover {
    color: red;
    transition: .3s ease-in;
}

.itens-menu-red {
    color: red;
}