.card {
    box-shadow: 0 0 0.3em black;
    background-color: #fff;
    transition: .3s ease-in;
}

.card:hover {
    box-shadow: 0 0 1.0em black;
}

.titulo {
    background-color: #565656;
    max-height: 40px;
    min-height: 40px;
    overflow: hidden;
}

.red {
    background-color: red;
}

.blue {
    background-color: #3596B5;
}

.tituloaltura {
    max-height: 100px;
    min-height: 100px;
    overflow: hidden;
}

.btn-detalhes:hover {
    background-color: #f21667;
    color: #fff;
}

.alinhamento {
    text-align: center;
}

.usuarios {
    max-height: 50px;
    min-height: 50px;
    overflow: hidden;
}

.foto {
    max-height: 200px;
    min-height: 200px;
}

.truncate-text {
    max-height: 2em; /* Ajuste a altura máxima conforme necessário */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gantt-container {
    display: flex;
    justify-content: space-between;
}

.gantt-table-container {
    flex: 1;
    /* Adicione mais estilos conforme necessário */
}

.gantt-chart-container {
    flex: 2;
    /* Adicione mais estilos conforme necessário */
}

.gantt-table {
    /* Estilos para a tabela */
    border-collapse: collapse;
    width: 100%;
}

.gantt-table th, .gantt-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.scrollable-div {
    overflow-y: auto;
    max-height: 1300px;
}

.scrollable-list {
    height: 800px; /* Altura desejada */
    overflow-y: auto;
}









